import React from "react";

const Divider = ({ backgroundColor }) => {
  return (
    <div className="divider" style={{ backgroundColor }}>
      <div className="pattern"></div>
    </div>
  );
};

export default Divider;
