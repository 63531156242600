import React, { useState, useEffect } from "react";
import "./css/Header.css";

// Define the easeInOutExpo function
const easeInOutExpo = (time, start, change, duration) => {
  if (time === 0) return start;
  if (time === duration) return start + change;
  time /= duration / 2;
  if (time < 1) return (change / 2) * Math.pow(2, 10 * (time - 1)) + start;
  time--;
  return (change / 2) * (-Math.pow(2, -10 * time) + 2) + start;
};

const Header = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024); // Track if the user is on desktop

  // Handle window resize to detect desktop or mobile
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [videoLoaded, setVideoLoaded] = useState(false);
  // Function to handle smooth scrolling to the #tickets section
  const scrollToTickets = () => {
    const targetElement = document.getElementById("tickets");
    if (!targetElement) return;

    const targetPosition =
      targetElement.getBoundingClientRect().top + window.scrollY;
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    const duration = 1500; // Duration of the scroll in milliseconds
    let start = null;

    const animation = (currentTime) => {
      if (!start) start = currentTime;
      const timeElapsed = currentTime - start;
      const run = easeInOutExpo(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    requestAnimationFrame(animation);
  };

  const handleVideoLoaded = () => {
    setVideoLoaded(true); // Mark video as loaded
  };

  return (
    <header className="header">
      <div className="lockup">
        <img
          className="groupwork"
          src="/images/groupwork@2x.png"
          alt="Groupwork"
          width="200"
        />
        <h1>
          <span>f</span>
          <span>i</span>
          <span>e</span>
          <span>l</span>
          <span>d</span>
          <span> </span>
          <span>s</span>
          <span>e</span>
          <span>r</span>
          <span>v</span>
          <span>i</span>
          <span>c</span>
          <span>e</span>
        </h1>
      </div>
      <div className="info">
        SATURDAY, SEP. 28, 2024
        <br />
        5:00–9:00 PM @ Silo City
      </div>

      <div className="scroll" onClick={scrollToTickets}>
        MORE INFO
        <img src="/images/down-arrow.svg" alt="More info" />
      </div>

      {isDesktop ? (
        <video
          className={`background-video ${videoLoaded ? "loaded" : ""}`}
          autoPlay
          muted
          loop
          preload // Delay the loading to reduce glitchiness
          poster="/images/single.jpg" // Fallback image displayed until video loads
          onLoadedData={handleVideoLoaded} // Marks video as loaded once it's ready
        >
          <source src="/videos/background-v2.mp4" type="video/mp4" />
        </video>
      ) : (
        <picture className="background-picture">
          <source srcSet="/images/single.jpg" />
          <img src="/images/single.jpg" alt="Header background" />
        </picture>
      )}
    </header>
  );
};

export default Header;
