import "./App.css";
import Header from "./Header";
import Nav from "./Nav";
import TicketSelector from "./TicketSelector";
import Divider from "./Divider";
import Happens from "./Happens";
import Performances from "./Performances";
import Location from "./Location";
import About from "./About";
import Footer from "./Footer";
import React, { useState, useEffect } from "react";
import Modal from "./Modal"; // Assuming you have a Modal component

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  // Function to extract session_id from URL
  const getSessionIdFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("session_id");
  };

  useEffect(() => {
    const sessionId = getSessionIdFromUrl();
    if (sessionId) {
      setSessionId(sessionId);
      setIsModalOpen(true); // Open modal when session_id is present in URL
    }
  }, []);

  // Close modal handler
  const closeModal = () => {
    setIsModalOpen(false);
    // Remove the session_id from the URL
    const newUrl = window.location.origin + window.location.pathname;
    window.history.replaceState(null, "", newUrl);
  };

  return (
    <div className="App">
      <Nav />
      <Header />
      <TicketSelector />
      <Divider backgroundColor={"#2A30A4"} />
      <div className="full-width offset-top">
        <picture>
          <source srcSet="/images/11.jpg" media="(min-width: 1200px)" />
          <source srcSet="/images/11@0.75x.jpg" media="(min-width: 800px)" />
          <source srcSet="/images/11@0.5x.jpg" media="(min-width: 500px)" />
          <source srcSet="/images/11@0.25x.jpg" media="(max-width: 499px)" />
          <img
            style={{ mixBlendMode: "exclusion" }}
            className="screen"
            src="/images/11.jpg"
            alt="The Trellis at Silo City"
          />
        </picture>
        <div className="filler"></div>
      </div>
      <Happens />
      <div
        style={{ backgroundColor: "#DF4036" }}
        className="offset-bottom full-width"
      >
        <picture>
          <source srcSet="/images/1.jpg" media="(min-width: 1200px)" />
          <source srcSet="/images/1@0.75x.jpg" media="(min-width: 800px)" />
          <source srcSet="/images/1@0.5x.jpg" media="(min-width: 500px)" />
          <source srcSet="/images/1@0.25x.jpg" media="(max-width: 499px)" />
          <img
            className="screen"
            style={{ mixBlendMode: "exclusion" }}
            src="/images/1.jpg"
            alt="The Trails at Silo City"
          />
        </picture>
      </div>
      <Divider backgroundColor={"#DF4036"} />
      <Performances />
      <Divider backgroundColor={"#1C1E46"} />
      <div className="full-width offset-top">
        <picture>
          <source srcSet="/images/3.jpg" media="(min-width: 1200px)" />
          <source srcSet="/images/3@0.75x.jpg" media="(min-width: 800px)" />
          <source srcSet="/images/3@0.5x.jpg" media="(min-width: 500px)" />
          <source srcSet="/images/3@0.25x.jpg" media="(max-width: 499px)" />
          <img
            style={{ mixBlendMode: "difference" }}
            className="screen"
            src="/images/11.jpg"
            alt="Trails around Silo City"
          />
        </picture>
        <div className="filler" style={{ backgroundColor: "#1C1E46" }}></div>
      </div>
      <Location />
      <div
        style={{ backgroundColor: "#000230" }}
        className="offset-bottom full-width"
      >
        {" "}
        <picture>
          <source srcSet="/images/5.jpg" media="(min-width: 1200px)" />
          <source srcSet="/images/5@0.75x.jpg" media="(min-width: 800px)" />
          <source srcSet="/images/5@0.5x.jpg" media="(min-width: 500px)" />
          <source srcSet="/images/5@0.25x.jpg" media="(max-width: 499px)" />
          <img
            style={{ mixBlendMode: "difference" }}
            className="screen"
            src="/images/1.jpg"
            alt="The Trails at Silo City"
          />
        </picture>
      </div>
      <Divider backgroundColor={"#000230"} />
      <About />
      <Footer />

      {/* Modal for showing transaction success */}
      {isModalOpen && sessionId && (
        <Modal onClose={closeModal}>
          <div className="text-center">
            <h3>TICKETS CONFIRMED</h3>
            <p>
              Your payment was successful. Ticket details have been sent to your
              email, and your name will be on the list at the door.
            </p>
            <p>
              For any questions,{" "}
              <a href="mailto:groupwork.fyi.bflo@gmail.com">contact us</a>. See
              you there!
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default App;
