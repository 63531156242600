import React from "react";
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import SvgMapComponent from "./SvgMap";

const Location = () => {
  return (
    <div id="location">
      <div className="content" style={{ paddingBottom: 0 }}>
        <h2>LOCATION</h2>
        <div>
          <h3>SILO CITY</h3>
          <p style={{ textAlign: "center", lineHeight: "160%" }}>
            85 Silo City Row
            <br />
            Buffalo, NY
            <br />
            <small>
              <strong>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com/maps/dir//85+Silo+City+Row,+Buffalo,+NY+14203/@42.9124987,-78.9039552,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89d3118a2e384909:0xd2cde942500afff!2m2!1d-78.8652192!2d42.8611306?entry=ttu&g_ep=EgoyMDI0MDgyNy4wIKXMDSoASAFQAw%3D%3D"
                >
                  GET DIRECTIONS
                </a>
              </strong>
            </small>
          </p>
        </div>
      </div>
      <div style={{ padding: 20, maxWidth: 960, margin: "auto" }}>
        <SvgMapComponent />
      </div>
      <div className="content">
        <p>
          Parking is available on a first-come basis. We suggest you carpool or
          bike down if possible. You'll find the entrance at the north edge of
          the green space.{" "}
          <small>
            <strong>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/dir//85+Silo+City+Row,+Buffalo,+NY+14203/@42.9124987,-78.9039552,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89d3118a2e384909:0xd2cde942500afff!2m2!1d-78.8652192!2d42.8611306?entry=ttu&g_ep=EgoyMDI0MDgyNy4wIKXMDSoASAFQAw%3D%3D"
              >
                GET DIRECTIONS
              </a>
            </strong>
          </small>
        </p>

        <p>
          <b>Field Service</b> is situated in the ecologically restored green
          areas of Silo City, a unique post-industrial landscape comprising the
          world’s largest collection of historical grain elevators. Reclaimed by
          wildlife and now engaged as a cultural space, Silo City has taken on a
          unique identity as a part of Buffalo's work towards a more sustainable
          and dynamic city.
        </p>
        <p>
          The urban wildland includes Buffalo River riparian habitat, upland
          meadow habitat, a pond, urban tree canopy and several pollinator
          zones. Our project aspires to be a model for environmentally sensitive
          artistic practices, engaging local ecological experts to ensure our
          work leaves no adverse impact on this cherished public space.
        </p>
        <p>
          <a href="https://duendesilo.city/buffalo-duende-at-silo-city-events">
            <b>Duende bar + restaurant</b>
          </a>{" "}
          will be open from 12:00pm until 11:00pm on Saturday. Please visit them
          before, during, or after the event for food and drink, or to use the
          restroom. Unfortunately they cannot allow alcoholic drinks to exit
          their grounds.
        </p>
      </div>
      <div style={{ paddingBottom: "3vw" }} className="full-width">
        <picture>
          <source srcSet="/images/9.jpg" media="(min-width: 1200px)" />
          <source srcSet="/images/9@0.75x.jpg" media="(min-width: 800px)" />
          <source srcSet="/images/9@0.5x.jpg" media="(min-width: 500px)" />
          <source srcSet="/images/9@0.25x.jpg" media="(max-width: 499px)" />
          <img
            style={{ mixBlendMode: "color-dodge" }}
            className="screen"
            src="/images/1.jpg"
            alt="The Trails at Silo City"
          />
        </picture>
      </div>
    </div>
  );
};

export default Location;
