import React from "react";

const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: "#000230",
        textAlign: "center",
        padding: 40,
        lineHeight: "180%",
      }}
    >
      <a href="mailto:groupwork.fyi.bflo@gmail.com">contact groupwork</a>
      <br />
      code + design 2024 <a href="mailto:init@pure.computer">pure computer</a>
      <br />
      (100% pure)
    </div>
  );
};

export default Footer;
