import React, { useState, useEffect } from "react";
import "./css/Performer.css"; // Assuming CSS is in Performer.css

const Performer = ({ name, location, time, image, moreInfoContent }) => {
  const [showModal, setShowModal] = useState(false);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  // Use effect to add/remove body class to prevent scrolling
  useEffect(() => {
    if (showModal) {
      // Add class to prevent scrolling when modal is open
      document.body.classList.add("no-scroll");
    } else {
      // Remove class to allow scrolling when modal is closed
      document.body.classList.remove("no-scroll");
    }

    // Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [showModal]);

  return (
    <div className="performer">
      <div className="performer-text">
        <h4>{name}</h4>
        <p>
          {location}
          {time ? (
            <span>
              <br />@ {time}
            </span>
          ) : null}
        </p>
        <button onClick={toggleModal}>
          INFO <span>»</span>
        </button>
      </div>
      <div className="performer-image">
        <img src={image} alt={name} />
      </div>

      {/* Modal for more information */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleModal}>
              &times;
            </span>
            {moreInfoContent}
          </div>
        </div>
      )}
    </div>
  );
};

export default Performer;
