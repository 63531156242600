import React from "react";

const About = () => {
  return (
    <div id="about">
      <div className="content" style={{ paddingBottom: 40 }}>
        <h2>ABOUT</h2>

        <p>
          <a href="https://groupwork.fyi" target="_blank" rel="noreferrer">
            <b>Groupwork</b>
          </a>{" "}
          is a Buffalo-based collective that seeks to create complex,
          multidimensional projects, blending experiential design, visual arts,
          and sound engineering. We are deeply committed to allowing audiences
          to engage with art on a sensory, intellectual, and emotional level.
        </p>

        <p style={{ textAlign: "center" }}>
          The team for Field Service includes:
        </p>
        <h3>GROUPWORK</h3>
        <ul>
          <li>
            <b>Zachary Brown</b> &mdash; Production Team, Volunteer Coordinator
          </li>
          <li>
            <b>Joshua Gruder</b> &mdash; Performer, Production Designer, Sound
            Designer, Marketing & Promotion
          </li>
          <li>
            <b>Alex Morrison</b> &mdash; Sound Engineer, Production Team
          </li>
          <li>
            <b>Frank Napolski</b> &mdash; Installation Artist, Lighting
            Designer, Graphic Designer, Web Developer
          </li>
          <li>
            <b>Dan Neveu</b> &mdash; Sound Engineer, Electrical Team
          </li>
          <li>
            <b>Travis Poling</b> &mdash; Performer, Production Team, Marketing
            &amp; Promotion
          </li>
          <li>
            <b>Max Smith</b> &mdash; Performer, Production Team, Volunteer
            Coordinator, Marketing & Promotion
            <br />
            <br />
            <br />
            <br />
          </li>
        </ul>
        <h3>GUEST ARTISTS &amp; PERFORMERS</h3>
        <ul>
          <li>
            <b>Jacob Brockway</b> &mdash; Performer (happygroupppp)
          </li>
          <li>
            <b>Alexander French</b> &mdash; Installation Artist
          </li>
          <li>
            <b>Jacob Kassay</b> &mdash; Installation Artist
          </li>
          <li>
            <b>M.J. Myers</b> &mdash; Installation Artist
          </li>
          <li>
            <b>Mario Fanone</b> &mdash; Performer (happygroupppp)
          </li>
          <li>
            <b>Nic Gordon</b> &mdash; Perfomer (happygroupppp)
          </li>
        </ul>
        <div style={{ textAlign: "center", marginTop: 120 }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="238"
            height="94"
            viewBox="0 0 119 47"
            fill="none"
          >
            <path
              d="M50.2062 17.5554V11.7254H48.5374C48.1462 11.7254 47.8291 11.4083 47.8291 11.017V10.2248C47.8291 9.83352 48.1462 9.51643 48.5374 9.51643H54.0839C54.4751 9.51643 54.7922 9.83352 54.7922 10.2248V11.017C54.7922 11.4083 54.4751 11.7254 54.0839 11.7254H52.415V16.7495C53.4332 16.2083 54.3286 15.468 55.0499 14.5798V10.2248C55.0499 9.83352 55.367 9.51643 55.7583 9.51643H56.5505C56.6989 9.51643 56.8365 9.56223 56.9503 9.64015C56.9579 9.49104 56.9622 9.34099 56.9622 9.19C56.9622 4.46423 53.1312 0.633083 48.4053 0.633083C43.6794 0.633083 39.8484 4.46411 39.8484 9.19C39.8484 13.9159 43.6794 17.7469 48.4053 17.7469C49.0232 17.7469 49.6254 17.6799 50.2061 17.5554H50.2062Z"
              fill="#FFD700"
            />
            <path
              d="M54.0839 10.2248V11.017H51.7067V18.1481H50.9144V11.017H48.5373V10.2248H54.0838H54.0839Z"
              fill="#FFD700"
            />
            <path
              d="M61.3044 10.2248V18.148H60.5121V14.1864H56.5505V18.148H55.7583V10.2248H56.5505V13.3942H60.5121V10.2248H61.3044Z"
              fill="#FFD700"
            />
            <path
              d="M64.5638 11.017V13.3942H69.3177V14.1865H64.5638V17.3559H69.3177V18.1482H63.7717V10.225H69.3177V11.0173H64.5638V11.017Z"
              fill="#FFD700"
            />
            <path
              d="M49.77 28.1313H48.9777V24.9619H49.77V28.1313ZM50.1661 25.1295L49.6028 24.5658L50.1661 24.0026L50.7298 24.5658L50.1661 25.1295ZM50.7298 28.5274L50.1661 29.0911L49.6028 28.5274L50.1661 27.9642L50.7298 28.5274ZM50.9584 24.3373L50.3951 23.7735L50.9584 23.2103L51.5221 23.7735L50.9584 24.3373ZM51.5221 29.3202L50.9584 29.8834L50.3951 29.3202L50.9584 28.7564L51.5221 29.3202ZM54.5239 23.3775H51.355V22.5852H54.5239V23.3775ZM51.355 26.5469H56.901V30.5085H56.1088V28.6951L55.7127 29.0911L55.149 28.5274L55.7127 27.9642L56.1088 28.3602V27.3391H51.3549V26.5468L51.355 26.5469ZM51.355 29.7164H54.5239V30.5086H51.355V29.7164ZM54.3568 23.7737L54.9205 23.2104L55.4838 23.7737L54.9205 24.3374L54.3568 23.7737ZM54.9205 28.7566L55.4838 29.3203L54.9205 29.8835L54.3568 29.3203L54.9205 28.7566ZM55.1491 24.5659L55.7128 24.0027L56.2761 24.5659L55.7128 25.1297L55.1491 24.5659ZM55.9418 25.3587L56.5051 24.795L57.0683 25.3587L56.5051 25.9219L55.9418 25.3587Z"
              fill="#FFD700"
            />
            <path
              d="M59.8432 23.3775V25.7546H64.5971V26.5469H59.8432V29.7164H64.5971V30.5086H59.0511V22.5854H64.5971V23.3777H59.8432V23.3775Z"
              fill="#FFD700"
            />
            <path
              d="M68.5516 23.7735L67.9884 24.3373L67.5923 23.9412V30.5085H66.8V22.5853H67.5923V23.6065L67.9884 23.2104L68.5516 23.7737V23.7735ZM69.3444 24.5658L68.7807 25.1295L68.2174 24.5658L68.7807 24.0026L69.3444 24.5658ZM69.969 28.1313H69.1767V24.9619H69.969V28.1313ZM70.9288 28.5274L70.3655 29.0911L69.8018 28.5274L70.3655 27.9642L70.9288 28.5274ZM72.3462 22.5852V30.5084H71.5539V29.4872L71.1578 29.8833L70.5941 29.32L71.1578 28.7563L71.5539 29.1524V22.5851L72.3462 22.5852Z"
              fill="#FFD700"
            />
            <path
              d="M75.6055 23.3775V25.7546H80.3593V26.5469H75.6055V29.7164H80.3593V30.5086H74.8133V22.5854H80.3593V23.3777H75.6055V23.3775Z"
              fill="#FFD700"
            />
            <path
              d="M83.3544 23.3775V25.7546H86.5238V26.5469H84.7099L85.1065 26.943L84.5427 27.5062L83.9795 26.943L84.3756 26.5469H83.3544V30.5085H82.5621V22.5853H86.5237V23.3776H83.3543L83.3544 23.3775ZM85.8986 27.7351L85.3349 28.2989L84.7717 27.7351L85.3349 27.1719L85.8986 27.7351ZM86.6909 28.5274L86.1276 29.0911L85.5639 28.5274L86.1276 27.9642L86.6909 28.5274ZM86.3562 23.7735L86.9199 23.2103L87.4831 23.7735L86.9199 24.3373L86.3562 23.7735ZM86.9199 24.7947L87.4831 25.3584L86.9199 25.9217L86.3562 25.3584L86.9199 24.7947ZM87.4831 29.32L86.9199 29.8833L86.3562 29.32L86.9199 28.7563L87.4831 29.32ZM88.2754 30.1123L87.7122 30.6756L87.1489 30.1123L87.7122 29.5486L88.2754 30.1123ZM88.1083 24.9619H87.316V24.1696H88.1083V24.9619Z"
              fill="#FFD700"
            />
            <path
              d="M95.3197 24.1697V30.5085H94.5274V27.3391H90.5658V30.5085H89.7736V24.1697H90.5658V26.5469H94.5274V24.1697H95.3197ZM90.962 24.3374L90.3988 23.7737L90.962 23.2104L91.5253 23.7737L90.962 24.3374ZM91.3581 23.3776V22.5853H93.7353V23.3776H91.3581ZM93.5676 23.7737L94.1313 23.2104L94.6946 23.7737L94.1313 24.3374L93.5676 23.7737Z"
              fill="#FFD700"
            />
            <path
              d="M102.277 22.5852V23.3775H99.9003V30.5085H99.108V23.3775H96.7308V22.5852H102.277H102.277Z"
              fill="#FFD700"
            />
            <path
              d="M104.04 28.1313H103.247V24.9619H104.04V28.1313ZM104.436 25.1295L103.872 24.5658L104.436 24.0026L104.999 24.5658L104.436 25.1295ZM104.999 28.5274L104.436 29.0911L103.872 28.5274L104.436 27.9642L104.999 28.5274ZM105.228 24.3373L104.665 23.7735L105.228 23.2103L105.792 23.7735L105.228 24.3373ZM105.792 29.3202L105.228 29.8834L104.665 29.3202L105.228 28.7564L105.792 29.3202ZM108.793 23.3775H105.625V22.5852H108.793V23.3775ZM105.625 29.7162H108.793V30.5085H105.625V29.7162ZM108.626 23.7735L109.19 23.2103L109.753 23.7735L109.19 24.3373L108.626 23.7735ZM109.19 28.7564L109.753 29.3202L109.19 29.8834L108.626 29.3202L109.19 28.7564ZM109.419 24.5658L109.982 24.0026L110.546 24.5658L109.982 25.1295L109.419 24.5658ZM109.982 27.9642L110.546 28.5274L109.982 29.0911L109.419 28.5274L109.982 27.9642ZM111.171 24.9619V28.1313H110.378V24.9619H111.171Z"
              fill="#FFD700"
            />
            <path
              d="M114.078 23.3775V25.7546H117.247V26.5469H115.433L115.83 26.943L115.266 27.5062L114.703 26.943L115.099 26.5469H114.078V30.5085H113.285V22.5853H117.247V23.3776H114.077L114.078 23.3775ZM116.622 27.7351L116.058 28.2989L115.495 27.7351L116.058 27.1719L116.622 27.7351ZM117.414 28.5274L116.851 29.0911L116.287 28.5274L116.851 27.9642L117.414 28.5274ZM117.079 23.7735L117.643 23.2103L118.206 23.7735L117.643 24.3373L117.079 23.7735ZM117.643 24.7947L118.206 25.3584L117.643 25.9217L117.079 25.3584L117.643 24.7947ZM118.206 29.32L117.643 29.8833L117.079 29.32L117.643 28.7563L118.206 29.32ZM118.999 30.1123L118.435 30.6756L117.872 30.1123L118.435 29.5486L118.999 30.1123ZM118.831 24.9619H118.039V24.1696H118.831V24.9619Z"
              fill="#FFD700"
            />
            <path
              d="M50.1223 38.1151H54.8762V38.9074H50.1223V42.8689H49.33V34.9458H54.876V35.738H50.1222V38.1152L50.1223 38.1151Z"
              fill="#FFD700"
            />
            <path
              d="M57.607 41.2845H56.8147V34.9458H57.607V41.2845ZM58.5663 41.6806L58.0031 42.2438L57.4399 41.6806L58.0031 41.1169L58.5663 41.6806ZM60.7763 42.0767V42.8689H58.3992V42.0767H60.7763ZM61.1724 41.1169L61.7357 41.6806L61.1724 42.2438L60.6087 41.6806L61.1724 41.1169ZM62.3608 34.9456V41.2844H61.5685V34.9456H62.3608Z"
              fill="#FFD700"
            />
            <path
              d="M66.4915 36.1341L65.9283 36.6978L65.5322 36.3017V42.8691H64.7399V34.9459H65.5322V35.9671L65.9283 35.571L66.4915 36.1341ZM67.2843 36.9264L66.7206 37.4901L66.1573 36.9264L66.7206 36.3631L67.2843 36.9264ZM67.9089 40.4919H67.1166V37.3224H67.9089V40.4919ZM68.8687 40.888L68.3055 41.4517L67.7418 40.888L68.3055 40.3247L68.8687 40.888ZM70.2861 34.9458V42.8689H69.4938V41.8478L69.0977 42.2438L68.534 41.6806L69.0977 41.1169L69.4938 41.513V34.9456H70.2861V34.9458Z"
              fill="#FFD700"
            />
            <path
              d="M75.9216 42.0767V42.8689H72.7528V34.9458H75.9216V35.738H73.5449V42.0768H75.9216V42.0767ZM75.7544 36.134L76.3182 35.5707L76.8814 36.134L76.3182 36.6977L75.7544 36.134ZM76.3182 41.1169L76.8814 41.6806L76.3182 42.2438L75.7544 41.6806L76.3182 41.1169ZM76.5467 36.9263L77.1104 36.363L77.6737 36.9263L77.1104 37.49L76.5467 36.9263ZM77.1104 40.3246L77.6737 40.8879L77.1104 41.4516L76.5467 40.8879L77.1104 40.3246ZM78.2988 37.3223V40.4918H77.5065V37.3223H78.2988Z"
              fill="#FFD700"
            />
            <path
              d="M34.1674 31.1218L19.4749 24.0536C26.0392 27.6088 32.7765 30.7741 39.504 34.0103L34.1674 31.1218Z"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M33.9752 31.5081L34.1674 31.1218L39.504 34.0103L39.3114 34.3974L33.9752 31.5081Z"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.2822 24.4404L24.5988 27.3208L24.5999 27.3231L33.0904 42.0022V42.0034"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.8131 27.0838H20.8118V27.0827L19.8903 25.4914V25.4902L19.889 25.4889L19.8731 25.4617V25.4606H19.8718V25.4593"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32.7585 42.26L33.0916 42.0047V42.0034H33.0904V42.0022H33.0891L32.3884 41.6198L32.3872 41.6185L27.7676 39.0972L24.2632 33.0455V33.0443"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8771 32.377L22.609 30.1874"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.8072 28.8009V28.8021L22.3858 29.8014V29.8025H22.387"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.035 27.4687L21.6148 28.4691"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M27.4349 39.3528L27.7675 39.0977L33.0918 42.0038L32.7586 42.2595L27.4349 39.3528Z"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.2817 28.7245L21.2806 28.7233L20.591 27.5316"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.1637 30.2491L21.4742 29.0575V29.0563"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8771 33.2083V33.2071L22.387 30.6352"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9492 24.6958L19.6474 25.0744L19.6486 25.0757H19.6497L22.8237 26.7965L22.8262 26.7977L24.2644 27.5773L32.3873 41.6185L32.3884 41.6198V41.621L32.7585 42.26L27.4346 39.3525L24.2632 33.8757"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.3689 27.148L20.3678 27.1468L19.1885 25.109L19.1724 25.0807H19.1711"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9492 24.9598V24.9795V27.3491V27.3503"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9492 37.5504V43.0273L23.8734 45.7311L24.2583 45.9421V45.9434H24.2595L24.2632 33.8757V33.8744V33.4094V33.238V33.0455V33.0443V33.0431L24.2644 27.5773L22.8262 26.7977L22.8237 26.7965L19.6497 25.0757H19.6486"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9492 30.9904V36.7153"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9492 27.7661V30.5093"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.565 43.0826L18.9497 43.0271L24.2592 45.9439L23.8739 45.9996L18.565 43.0826Z"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.5643 30.8042V27.8203"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.5643 27.4046V27.4034V24.771V24.7513L18.9479 24.9598H18.9492L19.1711 25.0807H19.1724L19.8718 25.4593V25.4606H19.8731L22.4388 26.8508V26.852H22.44L23.0457 27.1813H23.0469H23.0482L23.1925 27.259V27.2603L23.8784 27.6316V27.6328L23.8771 32.377V32.3795V33.2072V33.2083L23.8734 45.7311V45.7336V46L18.5655 43.0827V38.3226V38.3213"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.5655 37.4887L18.5643 31.2853V31.284"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.3193 35.3029L10.0641 41.8505V41.8517L15.0352 44.5914L15.3596 44.7702L18.5643 38.3238V38.3226L18.5655 38.3213L18.9492 37.5491V37.5479L19.4462 36.5475L22.387 30.6327L22.609 30.1862L23.8784 27.6328V27.6316L23.1925 27.2603V27.259L23.0482 27.1813H23.0469L22.44 26.852H22.4388V26.8508L19.8731 25.4606H19.8718V25.4593L19.1724 25.0807H19.1711L18.9492 24.9598H18.9479L18.5643 24.7513L18.563 24.7538L18.5569 24.7673H18.5556L17.1458 27.6056L17.1445 27.6069"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.9224 28.0547L13.7067 34.5233"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.72987 41.6926C11.6603 42.5887 13.4961 43.7429 15.3601 44.7696C13.4296 43.8737 11.5939 42.7194 9.72987 41.6926Z"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.652 35.8136L9.72976 41.6925L15.0253 44.6099L15.0352 44.5914V44.5901L18.5643 37.4925V37.4912V37.49L18.5655 37.4887L18.9492 36.7178V36.7165V36.7153L22.1637 30.2491L22.3858 29.8025H22.387L23.5452 27.4712H23.5441L23.1777 27.2714L23.0198 27.1863H23.0186V27.185L22.4117 26.8569H22.4105L22.4092 26.8556L19.8903 25.4902L19.889 25.4889L19.1885 25.109H19.1872L18.9492 24.9795H18.9479L18.5643 24.771H18.563V24.7698L18.5569 24.7673H18.5556V24.766L18.23 24.5897V24.591L18.2288 24.5921L16.6992 27.6699"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.4771 28.1175V28.1188H16.4759L13.038 35.0364"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.4771 28.1175V28.1188H16.4759L12.9678 28.6184H12.9665L3.66204 35.7407L3.48933 35.8727L8.77752 38.7802L12.652 35.8136V35.8123H12.6532L12.7963 35.7025L13.3194 35.3029V35.3017L13.3205 35.3004L18.563 31.2865L18.5643 31.2853L18.9479 30.9917L18.9492 30.9904L21.473 29.0575H21.4742V29.0563L21.806 28.8034V28.8021H21.8072L23.5429 27.4725L23.544 27.4712L23.1777 27.2714L23.0198 27.1863H23.0186V27.185L22.4117 26.8569H22.4105L22.4092 26.8556L19.8903 25.4902L19.889 25.4889L19.1885 25.109H19.1872L18.9492 24.9795H18.9479L18.5643 24.771H18.563V24.7698L18.5569 24.7673H18.5556V24.766L18.23 24.5897L13.6363 28.1064L16.6992 27.6699"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.29617 35.5399L3.48955 35.8723L8.77797 38.7802L8.58424 38.4473L3.29617 35.5399Z"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.1956 28.7281L3.29572 35.5397L3.66205 35.7407L8.58379 38.447L13.038 35.0363L13.7041 34.5269L13.7053 34.5257V34.5245L13.7065 34.5233L18.5629 30.8055L18.5641 30.8044L18.9478 30.5107L18.949 30.5096L21.2803 28.7247V28.7235L21.6134 28.4694H21.6146L23.1762 27.273L23.1774 27.2718L23.191 27.2607H23.1921V27.2595L23.3513 27.1386L22.7443 26.8092L22.7419 26.808L19.6976 25.1576H19.6964L18.9957 24.7777H18.9945V24.7765L18.9489 24.7531L18.9477 24.7519L18.0373 24.2585L12.8652 28.2169L13.6349 28.1071H13.6361L16.6989 27.6704M16.4769 28.1182V28.1194H16.4757L12.9676 28.619H12.9664L12.1967 28.7288"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.4771 28.1175L16.9212 28.0547H16.9224L18.563 27.8203H18.5643L18.9479 27.7661H18.9492L20.5897 27.5316H20.591L21.035 27.4687L23.0173 27.1863H23.0186V27.185L23.0346 27.1838L23.0457 27.1813H23.0469H23.0482L23.3516 27.1381L22.7447 26.8088L22.7422 26.8076L19.6979 25.157H19.6966L18.9961 24.7771H18.9948V24.776L18.9492 24.7525L18.9479 24.7513L18.0376 24.2578L1.60574 26.5941H1.60444L1.0013 26.6793L1.00248 26.6806H1.00378L6.28819 29.5707"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.28819 29.1537V29.5707L1.00378 26.6806H1.00248L1.0013 26.6793L1 26.2636L1.60445 26.5941H1.60574L6.28701 29.1537H6.28819Z"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.8237 26.7965L22.7422 26.8076L22.4388 26.8508V26.852L22.4092 26.8556L20.8131 27.0826H20.8118V27.0838L20.3678 27.1467H20.3665L18.9492 27.3491H18.9479L18.5643 27.4034H18.563L17.1458 27.6056H17.1433V27.6069L16.6992 27.6697L13.6363 28.1064H13.6352L12.8654 28.2162H12.8629L6.28819 29.1537H6.28701L1.60574 26.5941H1.60445L1 26.2636L17.5083 23.9175H17.5096L17.5182 23.9162L17.8908 23.8631H17.892L18.0376 23.8421L19.3463 24.5514L23.3516 26.7212H23.3491"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.0061 23.9198C19.7984 24.8313 21.5816 25.7622 23.3522 26.7216C16.6091 23.4784 9.85478 20.3085 3.27718 16.7421C5.0806 17.6096 6.88473 18.4719 8.68614 19.338"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.4702 16.354L3.27718 16.7421L8.57151 19.6127L8.76489 19.2238L3.4702 16.354Z"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.2303 23.4548L3.47019 16.354C10.0484 19.9194 16.8023 23.09 23.5452 26.3336L18.2303 23.4548Z"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.70968 8.7382L18.2304 23.4548L23.5452 26.3336L15.0164 11.591L9.70968 8.7382Z"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.0439 8.48273L9.70968 8.7382L15.0164 11.591L15.3512 11.335L10.0439 8.48273Z"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8792 26.0776L18.5637 23.1994L10.0439 8.48273L15.3512 11.335L23.6569 25.6934"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.5619 4.82241L18.563 16.8875V16.8888"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8784 25.8146V25.7936L23.8796 23.4276V23.012V20.2674V20.2661V19.7863L23.882 14.0528V14.0517V13.219L23.8832 7.66567L18.9479 5.02842"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.563 17.7214V17.7226V17.7239V23.1971V23.1994H18.5643L20.0001 23.9779H20.0013L20.0026 23.9791H20.0038L23.1764 25.6986L23.1789 25.6999L23.8784 26.0786"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.5626 4.82253L23.8842 7.66472L24.2703 7.61053L18.9482 4.76846"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9485 18.3884V23.1449L19.6344 23.5162L19.7799 23.5952L20.3881 23.9245L22.9538 25.3135H22.9551L23.6556 25.6934H23.6569L23.879 25.8143L24.265 26.0227V26.0018L24.2662 23.3731V22.9574V19.9711V19.4912L24.2687 13.2743"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9485 5.02807V17.5546"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.2687 12.4416L24.27 7.61112L18.9485 4.76905L18.5619 4.82241L18.563 16.8875V16.8888"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8826 14.0513V13.2188"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.882 13.219L20.443 20.1379V20.1391"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.2197 20.5857V20.5869L18.9479 23.1453L19.6325 23.5165H19.6338L19.7769 23.5942L19.7794 23.5954L20.385 23.9236L20.3875 23.9248L22.9532 25.3125V25.3137H22.9544H22.9557L23.655 25.6937H23.6563V25.6949H23.6588V25.6962L23.8784 25.8146L24.2644 26.023L24.273 26.007L25.6818 23.1724V23.1711H25.683L25.9037 22.7246L29.1184 16.2585L29.1196 16.256"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.1202 16.2556L29.505 15.4823V15.4811"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32.7863 8.88164V8.88046L27.7781 6.21347L27.4512 6.03957L24.2687 12.4416"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32.7863 8.88164V8.88035C31.089 8.05632 29.4397 7.10904 27.7769 6.21595C27.6684 6.1555 27.5598 6.09754 27.4513 6.03957L27.7844 6.20119L33.1194 9.04196C33.0085 8.98896 32.8974 8.93595 32.7864 8.88164H32.7863Z"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.2687 13.2743L27.7769 6.21594L27.7781 6.21346L27.7843 6.20118L33.1193 9.04195"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M30.1724 14.9716V14.9704"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.4424 20.9715L19.2828 23.3053L19.6503 23.5039L19.8095 23.5902H19.8108L20.4177 23.9196H20.4189L22.9352 25.2827L22.9365 25.2838L22.9377 25.2851L23.6372 25.6626L23.6384 25.6639L23.6397 25.665L23.879 25.7934L24.265 26.0018L24.2736 26.0067L24.5993 26.1831L26.1277 23.1079L26.3496 22.6613L29.7875 15.745"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8826 14.0513V14.0526L20.6656 20.5224"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.5044 15.4813L32.7857 8.88188C32.8966 8.93618 33.0077 8.98919 33.1187 9.0422L30.1718 14.9705"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.2662 19.4912L29.5039 15.4823H29.505V15.4811"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8796 19.7863L21.3569 21.7168L21.3558 21.7181"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.0227 21.9721V21.9733H21.0214V21.9746L19.2822 23.3056L19.6486 23.5041H19.6497L19.8077 23.5906H19.8089H19.8102L20.4158 23.9198H20.4171H20.4183L22.9346 25.2829L22.9359 25.2842L22.9371 25.2854H22.9384L22.9396 25.2867L23.6366 25.6628L23.6378 25.6641L23.6391 25.6653L23.6414 25.6666H23.6427L23.8784 25.7936L24.2644 26.0021L24.273 26.007L24.5988 26.1834L29.1813 22.674H29.1825H29.1837L29.8461 22.1646L29.8474 22.1633H29.8486L39.1568 15.0347L39.3345 14.8991L33.9921 12.0472L30.1718 14.9706V14.9719"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M34.1843 12.3799L33.9926 12.0472L39.3352 14.8986L39.5271 15.2319L34.1843 12.3799Z"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8795 20.2661L21.5494 22.0498"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.4747 23.6374H19.4758V23.6386L20.0828 23.9667L20.0852 23.968L23.1295 25.6159V25.6172H23.1308L23.8315 25.9971L23.8339 25.9984L23.8784 26.0218L24.7912 26.5165L29.9497 22.5655L29.951 22.5642H29.9522L30.6171 22.0548H30.6183V22.0536L39.5269 15.2321L39.1568 15.0347L34.1845 12.3802H34.1832L29.7869 15.7453V15.7465"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.1196 16.256V16.2572L24.2656 19.9713"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25.9043 22.7242L24.2662 22.957"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.349 22.6604H26.3503L29.8474 22.1633H29.8486"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M30.6171 22.0548L30.6183 22.0536H30.6208L36.4467 21.2258L41.7892 24.095L41.1773 24.1826H41.1761L24.7912 26.5165L23.8784 26.0218L23.8339 25.9984L23.8315 25.9971L23.1309 25.6172H23.1296V25.6159H23.1284L20.0852 23.968L20.084 23.9667H20.0828L19.4758 23.6386V23.6374"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.8089 23.5906L21.7925 23.3081H21.7936"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8801 23.0119L22.2395 23.2449"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.4968 24.0654L23.4799 26.2228L23.4811 26.224L24.7912 26.9333C24.8392 26.9259 24.8874 26.9197 24.9354 26.9123C25.0588 26.8951 25.1822 26.8777 25.3055 26.8593H25.308C30.8009 26.076 36.2951 25.294 41.788 24.5107"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.0169 23.6916L20.4171 23.9198"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8796 23.4276L22.4635 23.6288H22.4622"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.1271 23.1082L29.1813 22.674H29.1825H29.1837"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.951 22.5642H29.9522L36.4455 21.6416L41.1761 24.1826H41.1773L41.7879 24.5095V24.5107H41.7892V24.095"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25.683 23.1711L24.2656 23.3722"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M36.4458 21.6411L36.4462 21.226L41.7896 24.0945L41.789 24.5103L36.4458 21.6411Z"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8826 13.2188L23.8838 7.66543L24.27 7.61112L24.2687 12.4416"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.1202 16.2556L25.9043 22.7243L25.6824 23.1721L24.2736 26.0067L24.265 26.0227V26.0018L24.2662 23.3731V22.9574V19.9711V19.4912L24.2687 13.2743"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.5038 15.4823H29.5051V15.4811"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32.7863 8.88164V8.88046L33.1193 9.04195"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M30.1724 14.9716V14.9704"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.3496 22.6613L29.7875 15.745"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.349 22.6617L26.127 23.1082L24.5986 26.1834L29.1812 22.674H29.1824L29.846 22.1646L29.8472 22.1634H29.8483L39.1566 15.0348L39.3342 14.8992L39.5267 15.2322L30.6205 22.0536L30.6194 22.0548H30.6169L29.952 22.5642H29.9508L29.9496 22.5654L24.7911 26.5163L41.176 24.1825H41.1772L41.7891 24.0949L41.7879 24.5094V24.5106C36.295 25.2939 30.8008 26.0759 25.3079 26.8592H25.3054C25.1821 26.8777 25.0587 26.8949 24.9353 26.9122C24.8873 26.9196 24.8391 26.9257 24.7911 26.9332L31.2658 30.0479L31.3251 30.0762L34.0377 31.3813L39.5034 34.0099L39.311 34.3973L31.4607 30.6227L31.3349 30.5622L24.5998 27.323L24.5973 27.3218L33.0901 42.0032H33.0913V42.0044L32.7583 42.2598L32.3882 41.6209V41.6197L32.387 41.6185L24.2642 27.5772L24.263 33.0429V33.2378L24.2618 33.8719V33.8744L24.2582 45.9421V45.9445L23.8733 46V45.7311L23.877 33.2083V32.377L23.8781 27.6328L22.6088 30.1862V30.1874L22.3867 30.6327V30.6352L18.9488 37.5479V37.5504L18.5652 38.3213V38.3225H18.564V38.325L15.3592 44.7701L15.0249 44.6098L15.0348 44.5913V44.5901L18.564 37.4923V37.4898L18.5652 37.4887L18.9488 36.7178V36.7153L22.1634 30.2491L22.3854 29.8026V29.8015L23.5425 27.4726"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.8629 28.2162H12.8654L13.6351 28.1064H13.6363L16.6992 27.6699"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.4771 28.1175V28.1188H16.4759L12.9678 28.6184H12.9665L12.1968 28.7281"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.1956 28.7281L6.28818 29.5707V29.1537L12.8629 28.2162"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.4771 28.1175L16.9212 28.0547H16.9224L18.563 27.8203H18.5643L18.9479 27.7661H18.9492L20.5897 27.5316H20.591L21.035 27.4687L23.0173 27.1863H23.0186H23.0198L23.0346 27.1838L23.0457 27.1813H23.0469H23.0482L23.3516 27.1381L23.1925 27.259V27.2603H23.1913L23.1777 27.2714L23.1764 27.2726L21.6148 28.4691H21.6136L21.2817 28.7245H21.2806L18.9492 30.5093L18.9479 30.5106L18.5643 30.8042L18.563 30.8054L13.7067 34.5234L13.7054 34.5245V34.5257L13.7042 34.527L13.038 35.0364L8.58379 38.4472L8.77752 38.7802L12.652 35.8136V35.8123H12.6532L12.7963 35.7025L13.3194 35.3017L13.3205 35.3004L18.563 31.2865V31.2853H18.5643V31.284L18.9479 30.9917V30.9904H18.9492V30.9892L21.473 29.0575H21.4742V29.0563L21.806 28.8021H21.8072V28.801L23.5429 27.4725L23.544 27.4712"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.2882 29.5707L6.28702 29.1537H6.2882"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8784 25.7936L23.8796 23.4276V23.012V20.2674V20.2661V19.7863L23.882 14.0528V14.0517"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.0872 26.1144L19.5424 24.8907V24.8894L17.5182 23.9162L15.346 22.8725L14.8082 22.6135L14.7404 22.5802L8.57151 19.6124L8.60846 19.5371V19.5359L8.70102 19.3508L8.7035 19.3472V19.346L8.76394 19.2238H8.76512L14.7033 22.0794L15.0734 22.2583L15.3374 22.3853L22.0046 25.5937L22.2797 25.7257H22.281L23.544 26.3339V26.3326L23.4799 26.2241V26.2228L23.4343 26.1439L23.2862 25.8886V25.8873H23.285L23.2837 25.8862L23.1764 25.6986V25.6974L23.1752 25.6962L23.1296 25.6172V25.6159H23.1284L22.9544 25.3137H22.9532V25.3125L22.9371 25.2854L22.9359 25.2842L22.9346 25.2829L22.408 24.3689L22.0169 23.6929V23.6916H22.0157L21.7949 23.3092H21.7936V23.3081H21.7925L21.2139 22.3076V22.3065L21.0214 21.9746V21.9733L20.4417 20.9717L20.2197 20.5869V20.5857L18.9479 18.3887L18.563 17.7239V17.7226V17.7214L15.0155 11.5908H15.0166L15.351 11.3354L18.563 16.8875V16.8888L18.9479 17.5548L20.443 20.1379V20.1391L20.665 20.5215V20.5228L21.3558 21.7181L21.5482 22.0511L22.2389 23.2451H22.2402L22.4622 23.6288V23.63L22.6412 23.9384L23.6366 25.6628L23.6378 25.6641L23.6391 25.6653"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.6992 27.6699L17.1433 27.6069H17.1445L18.563 27.4046H18.5643L18.9479 27.3503H18.9492L20.3677 27.1481H20.3689L20.8118 27.0838H20.8131L22.4105 26.8569H22.4116L22.4388 26.852H22.44L22.7447 26.8088L22.8261 26.7977"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.2687 12.4416L27.4512 6.03957L27.7842 6.20119L27.7781 6.21347L27.7769 6.21595L24.2687 13.2743"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.882 13.219L20.443 20.1379V20.1391"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9479 17.5548V17.5524V4.76846"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.2197 20.5857V20.5869L18.9479 23.1453V18.3887"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.1956 28.7281L3.29559 35.5397L3.48932 35.8727L3.66203 35.7407L12.9665 28.6184"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.2288 24.5921L13.6363 28.1064"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.0376 23.8421L17.892 23.8631H17.8908L17.5182 23.9162L17.5096 23.9175H17.5083L1 26.2636V26.6805H1.00248H1.00378L1.60445 26.5941H1.60574L18.0376 24.2578L12.8654 28.2162"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.563 17.7214V17.7226V17.7239V23.1971V23.1994L10.4082 9.11504L10.4069 9.11256L10.0431 8.48344L9.71004 8.73761L9.70874 8.73879L18.23 23.4548L3.49181 16.3645H3.48933L3.46961 16.3547L3.27718 16.7419L4.54156 17.3501L8.70102 19.3508H8.7022"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.038 35.0364L16.4771 28.1188"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.3205 35.3004L13.3194 35.3017V35.3029L10.0641 41.8505V41.8517L10.0628 41.853L9.72976 41.6925L12.652 35.8136"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.9212 28.0547L13.7067 34.5233"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.563 27.4046V27.4034V24.771V24.7698V24.7538V24.7513L18.5556 24.766V24.7673L17.1433 27.6056"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.563 30.8054V27.8203"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.5643 37.49L18.563 31.2865"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9492 37.5504V43.0273L18.5655 43.0827H18.5643V38.325V38.3238V38.3226"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9479 30.9904V30.9917L18.9492 36.7153V36.7165"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9479 27.7661V30.5106"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.8718 25.4606H19.8731V25.4617L19.889 25.4889L19.8903 25.4902V25.4914L20.8118 27.0826H20.8131V27.0838"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.4758 24.0543H19.4771L19.4858 24.0592L19.4907 24.0617L19.4969 24.0654L19.5042 24.0692L19.5067 24.0703L23.2837 25.8862L23.285 25.8873H23.2862H23.2875L25.3056 26.8594H25.3067H25.308L34.1672 31.1224L34.0377 31.3815L34.0365 31.3852L33.9747 31.5086L27.3075 28.3002L27.2199 28.2582L27.0472 28.1755L19.2822 24.4404"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9479 24.9598V24.9795V27.3491V27.3503"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.3678 27.148V27.1468H20.3665L19.1872 25.1102V25.109L19.1711 25.0807"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.2806 28.7245V28.7233L20.5897 27.5316"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.1637 30.2491L21.473 29.0575"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8771 33.2083V33.2071L22.387 30.6352V30.634V30.6327"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.2632 33.0443V33.0455L27.7664 39.0985L27.4346 39.3525L24.2632 33.8757L24.2619 33.8744V33.8732V33.872"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.609 30.1874L23.8771 32.3795"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.806 28.8021V28.8034L22.3858 29.8014V29.8025"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.035 27.4687L21.6136 28.4691"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.6992 27.6699L18.2288 24.5921L18.23 24.591V24.5897"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.4635 23.63L23.8796 23.4276"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.4183 23.9198L22.0169 23.6929"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25.6818 23.1724L24.2656 23.3735"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.2408 23.2449C22.7873 23.1672 23.3338 23.0894 23.8802 23.0117"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.8095 23.5902C20.4707 23.4965 21.133 23.4027 21.7942 23.309H21.7954"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.2144 22.3061L19.6503 23.5039"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8802 20.2659L21.5488 22.0508"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.1202 16.2556L24.2662 19.9711"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M30.1724 14.9704L33.9927 12.0468L34.1838 12.3799L29.7875 15.7451"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.7875 15.745L30.1724 14.9715V14.9704"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.2662 19.4912L29.5039 15.4823H29.505"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.3558 21.7181L21.3569 21.7168L23.8796 19.7863"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.665 20.5228V20.5215L23.882 14.0528V14.0517"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.0227 21.9733L21.0214 21.9746L19.2822 23.3056L20.4417 20.9717L20.443 20.9706"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.2687 12.4416V13.2743"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.8826 14.0513V13.2188"
              stroke="#FFD700"
              strokeWidth="1.045"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <p style={{ textAlign: "center" }}>
          This project is supported by{" "}
          <a
            href="https://www.generatorfund.org"
            target="_blank"
            rel="noreferrer"
          >
            <b>The Generator Fund</b>
          </a>
          , a grant for artists administered by{" "}
          <a href="https://www.thebica.org" target="_blank" rel="noreferrer">
            <b>The Buffalo Institute for Contemporary&nbsp;Art</b>
          </a>{" "}
          and funded by{" "}
          <a
            href="https://warholfoundation.org"
            target="_blank"
            rel="noreferrer"
          >
            <b>The Andy Warhol Foundation for&nbsp;the&nbsp;Visual&nbsp;Arts</b>
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default About;
