import React from "react";
import Performer from "./Performer"; // Import the Performer component

const Performers = () => {
  const performersData = [
    {
      name: "NO\u00A0DRIFT",
      location: "IN THE MEADOW",
      time: "5:00 PM",
      image: "/images/no-drift.jpg",
      moreInfoContent: (
        <div>
          <p>
            As you arrive at&nbsp;5:00&nbsp;PM, the opening notes of NO DRIFT
            echo across the meadow.
          </p>
          <p>
            <small>More: </small>{" "}
            <a
              href="https://soundcloud.com/groupwork_fyi/groupwork-9112021-no-drift"
              target="_blank"
              rel="noreferrer"
            >
              NO DRIFT - Opening set (Sept 2021)
            </a>{" "}
          </p>
        </div>
      ),
    },
    {
      name: "I.Y.B.",
      location: "IN THE TRELLIS",
      time: "5:45 PM",
      image: "/images/iybtav.jpg",
      moreInfoContent: (
        <div>
          <p>
            As you wander through the green pathways, THE TRELLIS emerges—a
            willow dome crafted by the UB School of Architecture + Planning and
            Rigidized Metals. At&nbsp;5:45&nbsp;PM, beneath its arching
            branches, I.Y.B will begin a live generative-audio performance,
            filling the air with sounds that merge with the natural ambiance.
          </p>
          <p>
            <small>More: </small>{" "}
            <a
              href="https://www.silo.city/thetrellis"
              target="_blank"
              rel="noreferrer"
            >
              The Trellis
            </a>{" "}
            &bull;{" "}
            <a
              href="https://www.instagram.com/iybtp/"
              target="_blank"
              rel="noreferrer"
            >
              I.Y.B.
            </a>{" "}
          </p>
        </div>
      ),
    },
    {
      name: "happygroupppp",
      location: "IN THE MEADOW",
      time: "6:30 PM",
      image: "/images/happygroupppp.jpg",
      moreInfoContent: (
        <div>
          <p>
            As the golden hour arrives at&nbsp;6:30&nbsp;PM, happygroupppp
            transitions us toward dusk with a flowing mix of pedal steel,
            acoustic guitar, and electronics, blending seamlessly into the
            setting sun's glow across the meadow.
          </p>
          <small>
            <p>
              happygroupppp is an emerging trio from Buffalo, New York, offering
              a fresh, experimental integration of ambient, folk/country, and
              electronic music. The band's distinctive soundscapes combine
              synthesizer, pedal steel, acoustic guitar, and intermittent
              singing to capture sonic elements that seem to be moving in place.
              From echoes of bustling airports to the hum of neighborhoods to
              flowing waters of Niagara River, interwoven field recordings
              highlight often overlooked parts of familiar spaces and celebrate
              the unknown.
            </p>
            <p>
              The members are no strangers to the Buffalo, NY music community:
              Jacob Brockway, Mario Fanone, and Nic Gordon. Among them, projects
              of note include Mallwalkers, Black Canyon Lights, High Lonesome
              Sound, Johns, UVB77, Be Locust Or Alone, and the weekly events
              newsletter CYOA Buffalo. Their eclectic musical backgrounds
              contribute to the band's rich, textured sound. With their special
              blend of genres and instrumentation, happygroupppp offers an
              auditory experience that explores the liminal space between
              unfamiliar and familiar.
            </p>
          </small>
          <p>
            <small>More: </small>
            <a
              href="https://www.youtube.com/watch?v=OJuqXLTBH1s"
              target="_blank"
              rel="noreferrer"
            >
              happygroupppp - Nettles
            </a>{" "}
          </p>
        </div>
      ),
    },
    {
      name: "ARC OF CLOSURE",
      location: "IN THE MEADOW",
      time: "7:00 PM",
      image: "/images/arc.jpg",
      moreInfoContent: (
        <div>
          <p>
            As twilight settles, lie back and look up as ARC OF CLOSURE begins
            its ascent at&nbsp;7:00&nbsp;PM, guiding us into the evening. Sound
            mix by PEACE DEBT, visuals programming by NP.
          </p>
          {/* Add links or more content here */}
        </div>
      ),
    },
    {
      name: "CLOSING CEREMONY: PEACE\u00A0DEBT + NP",
      location: "IN THE TRELLIS",
      time: "7:45 PM",
      image: "/images/peace-debt.jpg",
      moreInfoContent: (
        <div>
          <p>
            Our closing ceremony takes place at&nbsp;7:45&nbsp;PM inside THE
            TRELLIS, where PEACE DEBT + NP offer an audiovisual experience to
            illuminate the night, bringing the community together under a canopy
            of light and sound.
          </p>
          <p>
            <small>More: </small>
            <a
              href="https://www.silo.city/thetrellis"
              target="_blank"
              rel="noreferrer"
            >
              The Trellis
            </a>{" "}
            &bull;{" "}
            <a
              href="https://www.instagram.com/grude.is.in.the.heart/"
              target="_blank"
              rel="noreferrer"
            >
              Peace Debt
            </a>{" "}
            &bull;{" "}
            <a
              href="https://www.instagram.com/nplsk/"
              target="_blank"
              rel="noreferrer"
            >
              NP
            </a>
          </p>
        </div>
      ),
    },
  ];

  const installationData = [
    {
      name: "NEW SPHERE ELECTRIC",
      location: "IN THE TUNNEL",
      image: "/images/new-sphere-electric.jpg",
      moreInfoContent: (
        <div>
          <p>
            If you listen closely, you may be drawn to the gentle, reverberating
            tones of NEW SPHERE ELECTRIC. This light-activated sound
            installation resides in a steel tunnel beneath DYLAN BURNS
            sculpture, RIDDLE STICKS, whose varied steel tubes create a unique
            blend of sounds when activated with a mallet.
          </p>
          <p>
            <small>MORE: </small>
            <a
              href="https://soundcloud.com/newsphereelectric/tracks"
              target="_blank"
              rel="noreferrer"
            >
              New Sphere Electric
            </a>{" "}
            &bull;{" "}
            <a
              href="https://www.silo.city/riddlesticks-burns"
              target="_blank"
              rel="noreferrer"
            >
              Riddle Sticks
            </a>{" "}
          </p>
        </div>
      ),
    },
    {
      name: "JACOB KASSAY",
      location: "UPON RIVER HILL",
      image: "/images/jk.jpg",
      moreInfoContent: (
        <div>
          <p>
            Further along, the labyrinthine paths of RIVER HILL await, an
            earthwork by DARA FRIEDMAN. Meandering through this space, you
            encounter a wind-activated soundscape by JACOB KASSAY, its tones
            weaving amongst the punctuated granite boulders and standing black
            locust logs.
          </p>
          <p>
            <small>MORE: </small>
            <a href="https://g.co/kgs/65Ak68N" target="_blank" rel="noreferrer">
              Jacob Kassay
            </a>{" "}
            &bull;{" "}
            <a
              href="https://www.silo.city/river-hill-friedman"
              target="_blank"
              rel="noreferrer"
            >
              River Hill
            </a>{" "}
          </p>
        </div>
      ),
    },
    {
      name: "M.J.\u00A0MYERS",
      location: "IN THE MEADOW",
      image: "/images/mj.jpg",
      moreInfoContent: (
        <div>
          <p>
            Suspended from the branches of an old cottonwood, wire mesh faces by
            MJ MYERS slowly turn, catching the light and your eye as they sway
            in the breeze.
          </p>
          <p>
            <small>MORE: </small>
            <a
              href="https://www.instagram.com/mrlaughingboy/"
              target="_blank"
              rel="noreferrer"
            >
              MJ Myers
            </a>
          </p>
        </div>
      ),
    },
    {
      name: "WANDERER / IN\u00A0SITU",
      location: 'AROUND "AGAIN"',
      image: "/images/wanderer.jpg",
      moreInfoContent: (
        <div>
          <p>
            Following the path down the hill, you'll uncover a hidden sound
            installation by WANDERER/IN SITU, inspired by the chorus of insects
            nestled around the flowering contour of the MARLENE MCCARTY
            earthwork, AGAIN.
          </p>
          <p>
            <small>MORE: </small>
            <a
              href="https://www.silo.city/again-mccarty"
              target="_blank"
              rel="noreferrer"
            >
              AGAIN
            </a>{" "}
            &bull;{" "}
            <a
              href="https://soundcloud.com/frankienp/silence
              "
              target="_blank"
              rel="noreferrer"
            >
              WANDERER/IN SITU
            </a>
          </p>
        </div>
      ),
    },
  ];

  return (
    <>
      <div id="performers" className="performers">
        <div className="content">
          <h2>Performers</h2>
          {performersData.map((performer, index) => (
            <Performer key={index} {...performer} />
          ))}
        </div>
      </div>
      <div id="installations" className="installations">
        <div className="content">
          <h2>Installations</h2>
          {installationData.map((performer, index) => (
            <Performer key={index} {...performer} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Performers;
