import React, { useState, useEffect } from "react";
import "./css/Nav.css";

// Define the easeInOutExpo function at the top level
const easeInOutExpo = (time, start, change, duration) => {
  if (time === 0) return start;
  if (time === duration) return start + change;
  time /= duration / 2;
  if (time < 1) return (change / 2) * Math.pow(2, 10 * (time - 1)) + start;
  time--;
  return (change / 2) * (-Math.pow(2, -10 * time) + 2) + start;
};

const Nav = () => {
  const [isBlurred, setIsBlurred] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsBlurred(true);
        setShowScrollToTop(true); // Show button when scrolling down
      } else {
        setIsBlurred(false);
        setShowScrollToTop(false); // Hide button when at the top
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the scroll event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showScrollToTop) {
      // Delay to trigger slide animation
      setTimeout(() => {
        setButtonVisible(true);
      }, 100); // Adjust this delay if needed
    } else {
      setButtonVisible(false);
    }
  }, [showScrollToTop]);

  // Function to handle smooth scrolling with exponential ease-in-out
  const scrollToSection = (event, id) => {
    event.preventDefault(); // Prevent the default anchor behavior

    const targetElement = document.getElementById(id);
    if (!targetElement) return;

    let targetPosition =
      targetElement.getBoundingClientRect().top + window.scrollY;
    const startPosition = window.scrollY;
    const duration = 1500; // Duration of the scroll in milliseconds
    let start = null;

    // Check if the viewport width is less than 768px (mobile size)
    const isMobileViewport = window.innerWidth < 768;
    const mobilePadding = 20; // Example padding in pixels for mobile

    if (isMobileViewport) {
      targetPosition -= mobilePadding; // Adjust target position with padding
    }

    const distance = targetPosition - startPosition; // Recalculate the distance after applying padding

    const animation = (currentTime) => {
      if (!start) start = currentTime;
      const timeElapsed = currentTime - start;
      const run = easeInOutExpo(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    requestAnimationFrame(animation);
    setIsMobileMenuOpen(false);
    document.getElementById("reverse").beginElement(); // Trigger the reverse animation to close the X icon
  };

  // Function to scroll to the top smoothly using Expo easing
  const scrollToTop = () => {
    const startPosition = window.scrollY;
    const distance = -startPosition; // Distance to the top is negative current scroll position
    const duration = 1500; // Duration of the scroll in milliseconds
    let start = null;

    const animation = (currentTime) => {
      if (!start) start = currentTime;
      const timeElapsed = currentTime - start;
      const run = easeInOutExpo(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    requestAnimationFrame(animation);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (!isMobileMenuOpen) {
      // Trigger the start animation to turn the hamburger to X
      document.getElementById("start").beginElement();
    } else {
      // Trigger the reverse animation to turn the X back to hamburger
      document.getElementById("reverse").beginElement();
    }
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      // Disable body scroll
      document.body.style.overflow = "hidden";
    } else {
      // Restore body scroll
      document.body.style.overflow = "auto";
    }

    // Cleanup effect to restore body scroll when the component unmounts or on menu close
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMobileMenuOpen]);

  return (
    <>
      <nav
        className={`top-nav ${isBlurred ? "blur" : ""} ${
          isMobileMenuOpen ? "no-blur" : ""
        }`}
      >
        <div className="nav-content">
          <div
            className="hamburger-menu"
            id="hamburgerMenu"
            onClick={toggleMobileMenu}
          >
            <svg
              className="hb"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 10 10"
              stroke="#FFD700"
              strokeWidth=".6"
              fill="rgba(0,0,0,0)"
              strokeLinecap="round"
              style={{ cursor: "pointer" }}
            >
              <path d="M2,3L5,3L8,3M2,5L8,5M2,7L5,7L8,7">
                <animate
                  dur="0.2s"
                  attributeName="d"
                  values="M2,3L5,3L8,3M2,5L8,5M2,7L5,7L8,7;M3,3L5,5L7,3M5,5L5,5M3,7L5,5L7,7"
                  fill="freeze"
                  begin="start.begin"
                />
                <animate
                  dur="0.2s"
                  attributeName="d"
                  values="M3,3L5,5L7,3M5,5L5,5M3,7L5,5L7,7;M2,3L5,3L8,3M2,5L8,5M2,7L5,7L8,7"
                  fill="freeze"
                  begin="reverse.begin"
                />
              </path>
              <rect width="10" height="10" stroke="none">
                <animate
                  dur="2s"
                  id="reverse"
                  attributeName="width"
                  begin="click"
                />
              </rect>
              <rect width="10" height="10" stroke="none">
                <animate
                  dur="0.001s"
                  id="start"
                  attributeName="width"
                  values="10;0"
                  fill="freeze"
                  begin="click"
                />
                <animate
                  dur="0.001s"
                  attributeName="width"
                  values="0;10"
                  fill="freeze"
                  begin="reverse.begin"
                />
              </rect>
            </svg>
          </div>

          <div className="nav-links">
            <a href="#tickets" onClick={(e) => scrollToSection(e, "tickets")}>
              Tickets
            </a>
            <a href="#happen" onClick={(e) => scrollToSection(e, "happen")}>
              What Happens
            </a>
            <a
              href="#performers"
              onClick={(e) => scrollToSection(e, "performers")}
            >
              Performers
            </a>
            <a
              href="#installations"
              onClick={(e) => scrollToSection(e, "installations")}
            >
              Installations
            </a>
            <a href="#location" onClick={(e) => scrollToSection(e, "location")}>
              Location
            </a>
            <a href="#about" onClick={(e) => scrollToSection(e, "about")}>
              About
            </a>
          </div>

          <a
            href="#tickets"
            className="tickets-link"
            onClick={(e) => scrollToSection(e, "tickets")}
          >
            Tickets
          </a>
        </div>
      </nav>
      <div
        className={`nav-links nav-links-mobile ${
          isMobileMenuOpen ? "open" : ""
        }`}
      >
        <a href="#tickets" onClick={(e) => scrollToSection(e, "tickets")}>
          Tickets
        </a>
        <a href="#happen" onClick={(e) => scrollToSection(e, "happen")}>
          What Happens
        </a>
        <a href="#performers" onClick={(e) => scrollToSection(e, "performers")}>
          Performers
        </a>
        <a
          href="#installations"
          onClick={(e) => scrollToSection(e, "installations")}
        >
          Installations
        </a>
        <a href="#location" onClick={(e) => scrollToSection(e, "location")}>
          Location
        </a>
        <a href="#about" onClick={(e) => scrollToSection(e, "about")}>
          About
        </a>
      </div>

      {/* Scroll to Top Button */}
      <button
        className={`scroll-to-top ${buttonVisible ? "visible" : ""}`}
        onClick={scrollToTop}
      >
        <img
          src="/images/up-arrow.svg"
          alt="Up Arrow"
          style={{ width: 16, height: 16 }}
        />{" "}
        Top
      </button>
    </>
  );
};

export default Nav;
