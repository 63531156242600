// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC5elR15GTSSIS81bOgMDfgUR1mHVLzG94",
  authDomain: "field-service-27834.firebaseapp.com",
  databaseURL: "https://field-service-27834-default-rtdb.firebaseio.com",
  projectId: "field-service-27834",
  storageBucket: "field-service-27834.appspot.com",
  messagingSenderId: "196741248758",
  appId: "1:196741248758:web:c5d5698bd2004987169ed7",
  measurementId: "G-PF2J3FV193",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Export the services
export { db };
