import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore"; // Import necessary Firestore functions
import "./css/Tickets.css";
import { db } from "./firebase";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const TicketSelector = () => {
  const [tickets, setTickets] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState({
    earlyBird: 0,
    presale: 0,
    final: 0,
    donation: 0,
  });
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for drawer

  useEffect(() => {
    const ticketsRef = collection(db, "tickets");
    const q = query(ticketsRef, orderBy("order")); // Order tickets by the 'order' field

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updatedTickets = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTickets(updatedTickets);
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, []);

  const handleSelectChange = (e, ticketType) => {
    const value = parseInt(e.target.value);

    setSelectedTickets((prev) => ({
      ...prev,
      [ticketType]: value,
    }));

    if (value > 0) {
      // Clear error message if a ticket is selected
      setErrorMessage("");
    }
  };

  const handleCheckout = async () => {
    // Check if at least one ticket is selected
    const totalTicketsSelected = Object.values(selectedTickets).reduce(
      (total, num) => total + num,
      0
    );

    if (totalTicketsSelected === 0) {
      setErrorMessage("Please select at least one ticket to proceed.");
      return;
    }

    // Clear any previous error message if selection is valid
    setErrorMessage("");

    const isEmulator = process.env.REACT_APP_FIREBASE_EMULATOR === "true";
    const functionUrl = isEmulator
      ? "http://localhost:5001/field-service-27834/us-central1/app/create-checkout-session"
      : "https://us-central1-field-service-27834.cloudfunctions.net/app/create-checkout-session"; // Use the real URL for production

    const stripe = await stripePromise;
    const response = await fetch(functionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedTickets),
    });

    if (!response.ok) {
      console.error("Error creating checkout session:", await response.text());
      return;
    }

    const session = await response.json();

    // Redirect to Stripe Checkout
    await stripe.redirectToCheckout({ sessionId: session.id });
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // Helper function to determine if a ticket tier should be disabled
  const isTierDisabled = (ticketId) => {
    const earlyBirdTicket = tickets.find((ticket) => ticket.id === "earlyBird");
    const presaleTicket = tickets.find((ticket) => ticket.id === "presale");
    const finalTicket = tickets.find((ticket) => ticket.id === "final");
    const donationTicket = tickets.find((ticket) => ticket.id === "donation");

    if (ticketId === "earlyBird") {
      return earlyBirdTicket && earlyBirdTicket.available <= 0; // Disable if early bird tickets are sold out
    }

    if (ticketId === "presale") {
      return (
        (earlyBirdTicket && earlyBirdTicket.available > 0) ||
        (presaleTicket && presaleTicket.available <= 0)
      ); // Disable if early bird tickets are available or presale is sold out
    }

    if (ticketId === "final") {
      return (
        (presaleTicket && presaleTicket.available > 0) ||
        (finalTicket && finalTicket.available <= 0)
      ); // Disable if presale tickets are available or final tickets are sold out
    }

    if (ticketId === "donation") {
      return donationTicket && donationTicket.available <= 0; // Disable if donation tickets are sold out
    }

    return false; // Default case, should not happen
  };

  // Helper function to determine the maximum quantity that can be selected
  const getMaxQuantity = (ticketId, available) => {
    if (ticketId === "earlyBird") {
      return Math.min(available, 4); // Max 4 for early bird tickets
    }
    return Math.min(available, 6); // Max 6 for all other ticket types
  };

  // Helper function to format price from cents to dollars
  const formatPrice = (priceInCents) => {
    return (priceInCents / 100).toFixed(2); // Convert cents to dollars and format to two decimal places
  };

  // Determine if all tickets are sold out
  const allTicketsSoldOut = tickets.every((ticket) => ticket.available <= 0);

  return (
    <div id="tickets" className="tickets">
      <div className="content">
        <h2>Select Your Tickets</h2>

        <div className="tickets-table">
          {tickets.map((ticket) => {
            const isDisabled = isTierDisabled(ticket.id);
            return (
              <div
                key={ticket.id}
                className={isDisabled ? "disabled ticket-tier" : "ticket-tier"}
              >
                <h3>
                  <span className={ticket.available > 0 ? "" : "soldout"}>
                    {ticket.name} &mdash; ${formatPrice(ticket.price)}
                  </span>{" "}
                  {ticket.available > 0 ? "" : <small>Sold Out</small>}
                </h3>
                <div className="custom-select-wrapper">
                  <select
                    value={selectedTickets[ticket.id] || 0}
                    onChange={(e) => handleSelectChange(e, ticket.id)}
                    disabled={isDisabled} // Disable based on the tier rules, including donation
                  >
                    {[
                      ...Array(
                        getMaxQuantity(ticket.id, ticket.available) + 1
                      ).keys(),
                    ].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span className="custom-caret">›</span>
                </div>
              </div>
            );
          })}
          <span className="ticket-info">
            <b>All ages welcomed.</b>
            <br />
            Free for children ages 12 and under.
          </span>
          {errorMessage && <p className="error-message">{errorMessage}</p>}{" "}
          {/* Display error message */}
          {!allTicketsSoldOut && (
            <button className="checkout" onClick={handleCheckout}>
              Buy Tickets
            </button>
          )}
          {/* New Drawer Toggle Button */}
          <p className="text-center" style={{ marginTop: 48 }}>
            <small>
              Donor Tickets get you a regular ticket, and the extra provides a
              discounted ticket to a person who requests financial assistance.
            </small>
          </p>
          {!isDrawerOpen && (
            <button className="financial-assistance" onClick={toggleDrawer}>
              Need financial assistance?
            </button>
          )}
          {/* Drawer Content */}
          {isDrawerOpen && (
            <div className="financial-assistance-drawer">
              <div className="ticket-info more">
                <p>
                  <b>
                    For those who want to attend but need financial assistance:
                  </b>{" "}
                  We have a small pool of discounted tickets set aside for you.
                  We are also seeking volunteers to help with setup, assisting
                  during the event, and breakdown.{" "}
                  <a
                    href="https://iyrc9a98v4k.typeform.com/to/dDsHtSUj"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <b>Please fill out this form</b>
                  </a>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketSelector;
