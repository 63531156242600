import React from "react";

const Happens = () => {
  return (
    <div id="happen" className="happen">
      <div className="content">
        <h2>What Will Happen</h2>
        <p>
          <b>Groupwork</b>, an interdisciplinary Buffalo-based collective,
          provides <b>Field Service</b>. An immersive tableau fusing the
          ethereal and the elemental. Embedded within the trees, skies, and
          fields, video art, light and soundscapes illuminate and augment the
          natural surroundings. This project aims to help audiences redefine
          their understanding of natural space through a curated sensory
          experience. Staged upon the living canvas of <b>Silo City</b> — a
          unique post-industrial landscape — the project aims to blur the
          boundaries between natural and digital landscapes. How can light and
          audio create a specific feeling or atmosphere? How well do we really
          know our city, and what hidden elements might exist?
        </p>
        <h3 className="text-center" style={{ marginTop: 80 }}>
          WHAT YOU{" "}
          <span
            style={{ display: "inline-block", borderBottom: "2px solid white" }}
          >
            SHOULD
          </span>{" "}
          BRING
        </h3>
        <div>
          <ul>
            <li>A lawn blanket or camping chair</li>
            <li>A re-usable container for water/tea</li>
            <li>
              Layered clothing and durable shoes or boots (it will probably get
              a little chilly as night approaches!)
            </li>
            <li>Bug spray and sunscreen</li>
            <li>A friendly attitude and an open mind</li>
          </ul>
        </div>
        <h3 className="text-center" style={{ marginTop: 80 }}>
          THE TIME PASSES
        </h3>

        <p>
          As you arrive at 5:00 PM, the opening notes of <b>NO DRIFT</b> echo
          across the meadow. Suspended from the branches of an old cottonwood,
          wire mesh faces by <b>MJ MYERS</b> slowly turn, catching the light and
          your eye as they sway in the breeze. Following the path down the hill,
          you'll uncover a hidden sound installation by <b>WANDERER/IN SITU</b>,
          inspired by the chorus of insects, nestled around the flowering
          contour of the
          <b> MARLENE MCCARTY</b> earthwork, <b>AGAIN</b>.
        </p>
        <p>
          As you wander through the green pathways, <b>THE TRELLIS</b> emerges —
          a willow dome crafted by the{" "}
          <b>UB School of Architecture &amp; Planning</b> and{" "}
          <b>Rigidized Metals</b>. At 5:45 PM, beneath its arching branches,{" "}
          <b>I.Y.B</b> will begin a live generative-audio performance, filling
          the air with sounds that merge with the natural ambiance.
        </p>
        <p>
          Further along, the labyrinthine paths of <b>RIVER HILL</b> await, an
          earthwork by <b>DARA FRIEDMAN</b>. Meandering through this space, you
          encounter a wind-activated soundscape by <b>JACOB KASSAY</b>, its
          tones weaving amongst the punctuated granite boulders and standing
          black locust logs.
        </p>
        <p>
          If you listen closely, you may be drawn to the gentle, reverberating
          tones of <b>NEW SPHERE ELECTRIC</b>. This light-activated sound
          installation resides in a steel tunnel beneath <b>DYLAN BURNS</b>{" "}
          sculpture, <b>RIDDLE STICKS</b>, whose varied steel tubes create a
          unique blend of sounds when activated with a mallet.
        </p>

        <p>
          As the golden hour arrives at 6:30 PM, <b>happygroupppp</b>{" "}
          transitions us toward dusk with a flowing mix of pedal steel, acoustic
          guitar, and electronics, blending seamlessly into the setting sun's
          glow across the meadow. As twilight settles, lie back and look up as{" "}
          <b>ARC OF CLOSURE</b> begins its ascent at 7:00 PM, guiding us into
          the evening.
        </p>
        <p>
          Our closing ceremony takes place inside <b>THE TRELLIS</b> at 7:45 PM,
          where <b>PEACE DEBT + NP</b> offer an audiovisual experience to
          illuminate the night, bringing the community together under a canopy
          of light and sound.
        </p>
        <h3 className="text-center" style={{ marginTop: 80 }}>
          ADDITIONAL INFO
        </h3>

        <p>
          This event is alcohol-free.{" "}
          <a href="https://duendesilo.city">
            <b>Duende bar + restaurant</b>
          </a>{" "}
          will be open before, during, and after the event. Please visit them
          for food and drink, or to use the restroom. Guests are welcome to
          come-and-go as they please during the four hour run-time.
        </p>
        <p>
          For more details, please read the <b>LOCATION</b> information section.
        </p>

        <p>
          We'll need some extra hands to produce this, so if you're interested
          in <b>VOLUNTEERING</b>,{" "}
          <a
            href="https://iyrc9a98v4k.typeform.com/to/dDsHtSUj"
            target="_blank"
            rel="noreferrer"
          >
            <b>please fill out this form</b>
          </a>
          .
        </p>

        <p>
          <b>Field Service</b> is designed to resonate with a diverse audience,
          ranging from the art-curious and digital natives to the everyday
          residents of Buffalo and surrounding communities. Whether someone is a
          seasoned art connoisseur, a music enthusiast, or someone simply
          intrigued by the unexpected harmony between the digital and the
          natural world, this experience is crafted with them in mind.
        </p>
      </div>
    </div>
  );
};

export default Happens;
